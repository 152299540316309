import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import PublicRoutes from "./routes/PublicRoutes";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import ProvideAuth from "./context/Auth";
import ThemeProvider from "./providers/ThemeProvider";
import COGNITO from "./aws-exports.js";
import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { defaultStorage } from "aws-amplify/utils";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO.USER_POOL_ID,
      region: COGNITO.REGION,
      userPoolClientId: COGNITO.APP_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: COGNITO.REACT_APP_COGNITO_DOMAIN,
          scopes: ["openid email profile aws.cognito.signin.user.admin"],
          redirectSignIn: [COGNITO.REDIRECT_SIGNIN],
          redirectSignOut: [COGNITO.REDIRECT_SIGNOUT],
          responseType: "code",
        },
      },
    },
  },
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

const App = () => {
  return (
    <Router>
      <div className="App">
        <ThemeProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ProvideAuth>
                <PublicRoutes />
              </ProvideAuth>
              <ProtectedRoutes />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </div>
    </Router>
  );
};

export default App;
